import { put, takeLatest, call, all, takeEvery } from "redux-saga/effects";
import axios from "axios";

export function* getCourses(action) {
  try {
    const response = yield call(
      axios.get,
      `/api/franchise/all-courses?sectorId=${
        action.sectorId ?? ""
      }&categoryId=${action?.categoryId ?? ""}`,
      { headers: { Authorization: `Bearer ${action.payload}` } }
    );
    yield put({ type: "GET_COURSES_FULFILLED", payload: response.data });
  } catch (error) {
    yield put({ type: "GET_COURSES_REJECTED", payload: error.response.data });
  }
}

export function* bookCourse(action) {
  try {
    const response = yield call(
      axios.post,
      `/api/franchise/book-course`,
      action.payload,
      { headers: { Authorization: `Bearer ${action.token}` } }
    );
    yield put({ type: "@BOOK_COURSE_SUCCESS", payload: response.data });
  } catch (error) {
    yield put({ type: "@BOOK_COURSE_FAILED", payload: error.response.data });
  }
}

export default function* CourseSaga() {
  yield all([
    takeEvery("GET_COURSE_PENDING", getCourses),
    takeEvery("@BOOK_COURSE_REQUEST", bookCourse),
  ]);
}
