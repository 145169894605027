import { put, takeLatest, call, all } from "redux-saga/effects";
import axios from "axios";

function* bookServices(action) {
  try {
    const response = yield call(
      axios.post,
      "/api/franchise/add-services",
      action.payload,
      {
        headers: { Authorization: `Bearer ${action.token}` },
      }
    );
    yield put({ type: "@ADD_SERVICES_SUCCESS", payload: response.data });
  } catch (error) {
    yield put({
      type: "@ADD_SERVICES_FAILED",
      payload: error.response.data,
    });
  }
}




function* getServicesList(action) {
  try {
    const response = yield call(
      axios,
      `/api/franchise/service-list?sort=${action?.sort}&page=${action?.page}&search=${action?.search}&paginate=${action?.paginate}`,
      {
        headers: { Authorization: `Bearer ${action?.payload}` },
      }
    );
    yield put({
      type: "GET_SERVICES_LIST_FULLFILLED",
      payload: response?.data,
    });
  } catch (error) {
    yield put({
      type: "GET_SERVICES_LIST_REJECTED",
      payload: error?.response?.data,
    });
  }
}

function* purchasedServiceList(action) {
  try {
    const response = yield call(
      axios,
      `/api/franchise/service-order-details?sort=${action?.sort}&page=${action?.page}&search=${action?.search}&paginate=${action?.paginate}&start=${action?.startDate}&end=${action?.endDate}&gateWayType=${action?.gatewayType}&memberType=${action?.status}`,
      {
        headers: { Authorization: `Bearer ${action?.payload}` },
      }
    );
    yield put({
      type: "GET_PURCHASED_SERVICES_LIST_FULLFILLED",
      payload: response?.data,
    });
  } catch (error) {
    yield put({
      type: "GET_PURCHASED_SERVICES_LIST_REJECTED",
      payload: error?.response?.data,
    });
  }
}

function* bookedServicesList(action) {
  try {
    const response = yield call(
      axios.get,
      `/api/franchise/booked-user-services?user_id=${action.payload}`,
      {
        headers: { Authorization: `Bearer ${action.token}` },
      }
    );
    yield put({
      type: "GET_BOOKED_SERVICES_LIST_FULLFILLED",
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: "GET_BOOKED_SERVICES_LIST_REJECTED",
      payload: error.response.data,
    });
  }
}

export default function* ServiceSaga() {
  yield all([
    takeLatest("@ADD_SERVICES_REQUEST", bookServices),
    takeLatest("GET_SERVICES_LIST", getServicesList),
    takeLatest("GET_PURCHASED_SERVICES_LIST", purchasedServiceList),
    takeLatest("GET_BOOKED_SERVICES_LIST", bookedServicesList),
  ]);
}
