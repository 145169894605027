import { put, takeLatest, call, all } from "redux-saga/effects";
import axios from "axios";
function* getUserDetailsByID(action) {
    try {
        const response = yield call(axios, `/api/franchise/get-user-detail/${action?.id}`, {
            headers: {
                Authorization: `Bearer ${action.token}`
            }
        })
        yield put({
            type: "GET_USER_DETAIL_FULFILLED",
            payload: response.data,
        })
    } catch (error) {
        yield put({
            type: "GET_USER_DETAIL_FAILED",
            payload: error.response.data,
        })
    }
}

export default function* userDetailSaga() {
    yield all([takeLatest('GET_USER_DETAIL', getUserDetailsByID)])
}