import _ from "lodash";

const initialState = {
  createNote: [],
  createNoteLoading: false,
  createNoteStatus: false,
  Note: [],
  loading: false,
  listStatus: false,
  getNotebyIdLoading: false,
  getNotebyIdStatus: false,
  getNotebyId: [],
  updateNoteLoader: false,
  updateNoteStatus: false,
  updateNote: [],
  deleteReminder: [],
  error: {},
  userNotes: []
};

const noteReducer = (state = initialState, actions) => {
  const { payload } = actions;
  // eslint-disable-next-line default-case
  switch (actions.type) {
    case "@ADD_NOTE_PENDING":
      state = {
        ...state,
        createNoteLoading: true,
        createNoteStatus: false,
        error: {},
      };
      break;
    case "@ADD_NOTE_SUCCESS":
      state = {
        ...state,
        createNoteLoading: false,
        createNoteStatus: true,
        createNote: _.get(payload, "data", {}),
      };
      break;
    case "@ADD_NOTE_FAILED":
      const {
        payload: {
          response: { status, data },
        },
      } = actions;
      if (status === 422) {
        state = {
          ...state,
          createNoteLoading: false,
          createNoteStatus: false,
          error: _.get(data, "errors", {}),
          loading: false,
        };
      } else {
        state = {
          ...state,
          createNoteLoading: false,
          createNoteStatus: false,
          loading: false,
          error: _.get(data, "message", {}),
        };
      }
      break;
    case "@GET_NOTE_PENDING":
    case "GET_USER_NOTE_LIST":
      state = {
        ...state,
        loading: true,
        getNotebyIdStatus: false,
        createNoteStatus: false,
        listStatus: false,
        error: {},
      };
      break;
    case "@GET_NOTE_SUCCESS":
      state = {
        ...state,
        loading: false,
        listStatus: true,
        Note: payload,
      };
      break;

    case "GET_USER_NOTE_LIST_SUCCESS":
      return {
        ...state, loading: false, userNotes: payload, loading: false,
        listStatus: true,
      }

    case "RESET_UPDATE_NOTE_STATUS":
      return{
        updateNoteStatus: payload
      }
    case "@GET_NOTE_FAILED":
    case "GET_USER_NOTE_LIST_FAILED":
      state = {
        ...state,
        loading: false,
        listStatus: false,
        error: _.get(payload.response.data, "message", {}),
      };
      break;
    case "NOTE_DELETE_PENDING":
      state = {
        ...state,
        loading: true,
        listStatus: false,
        error: {},
      };
      break;
    case "NOTE_DELETE_FULFILLED":
      state = {
        ...state,
        loading: false,
        listStatus: true,
        deleteReminder: _.get(payload, "data", {}),
      };
      break;
    case "NOTE_DELETE_REJECTED":
      state = {
        ...state,
        loading: false,
        listStatus: false,
        error: _.get(payload.response.data, "error", {}),
      };
      break;
    case "NOTE_BY_SLUG_PENDING":
      state = {
        ...state,
        getNotebyIdLoading: true,
        getNotebyIdStatus: false,
        error: {},
      };
      break;
    case "NOTE_BY_SLUG_FULFILLED":
      state = {
        ...state,
        getNotebyIdLoading: false,
        getNotebyIdStatus: true,
        getNotebyId: _.get(payload, "data", {}),
      };
      break;
    case "NOTE_BY_SLUG_REJECTED":
      state = {
        ...state,
        getNotebyIdLoading: false,
        getNotebyIdStatus: false,
        error: _.get(payload.response.data, "errors", {}),
      };
      break;
    case "UPDATE_NOTE_PENDING":
      state = {
        ...state,
        updateNoteLoader: true,
        updateNoteStatus: false,
        error: {},
      };
      break;
    case "UPDATE_NOTE_FULFILLED":
      state = {
        ...state,
        updateNoteLoader: false,
        updateNoteStatus: true,
        updateNote: _.get(payload, "data", {}),
      };
      break;

    case "GET_FRANCHISE_LIST_NOTE_FULFILLED":
      return { ...state, franchiseListNotes: payload?.data?.note };
    case "GET_ASSOCIATE_LIST_NOTE_FULFILLED":
      return { ...state, associateListNotes: payload?.data?.note };
    case "GET_INSTITUTE_LIST_NOTE_FULFILLED":
      return { ...state, instituteListNotes: payload?.data?.note };
    case "UPDATE_NOTE_REJECTED":
      if (status === 422) {
        state = {
          ...state,
          updateNoteLoader: false,
          updateNoteStatus: false,
          error: _.get(payload.response.data, "errors", {}),
        };
      } else {
        state = {
          ...state,
          updateNoteLoader: false,
          updateNoteStatus: false,
          error: _.get(payload.response.data, "message", {}),
        };
      }
      break;
  }
  return state;
};

export default noteReducer;
