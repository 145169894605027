import { put, takeEvery, call, all } from "redux-saga/effects"
import axios from "axios";


function* logintoTeleCall(action) {
  try {
    const response = yield call(
      axios.post,
      `/api/franchise/smartflow/login`,
      action.payload.data,
      {
        headers: { Authorization: `Bearer ${action.payload.token}` },
      },
    );
    yield put({ type: "LOGIN_TO_CALL_FULFILLED", payload: response });
  } catch (error) {
    yield put({ type: "LOGIN_TO_CALL_REJECTED", payload: error });
  }
}


function* startTeleCall(action) {
  try {
    const response = yield call(
      axios.post,
      `/api/franchise/smartflow/clicktocall`,
      action.payload.data,
      {
        headers: { Authorization: `Bearer ${action.payload.token}` },
      },
    );
    yield put({ type: "CALLING_FULFILLED", payload: response });
  } catch (error) {
    yield put({ type: "CALLING_REJECTED", payload: error });
  }
}


function* hangUpCalling(action) {
  try {
    const response = yield call(
      axios.post,
      `/api/franchise/smartflow/clicktocall`,
      action.payload.data,
      {
        headers: { Authorization: `Bearer ${action.payload.token}` },
      },
    );
    yield put({ type: "CALL_HANG_UP_FULFILLED", payload: response });
  } catch (error) {
    yield put({ type: "CALL_HANG_UP_REJECTED", payload: error });
  }
}




export default function* LoginCallAction() {
  yield all([takeEvery("LOGIN_TO_CALL", logintoTeleCall),
  takeEvery("CALLING", startTeleCall),
  takeEvery("CALL_HANG_UP",hangUpCalling)
  ])
}