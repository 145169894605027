import _ from "lodash";

const initialState = {
  isLoading: false,
  errors: {},
  addedp: {},
  edpCreated: false,
  edps:{},
  purchasedEdp:{}
};

const edpReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "@ADD_EDP_REQUEST":
    case "GET_EDP_PURCHASE_LIST":
      return {
        ...state,
        isLoading: true,
        errors: {},
      };
    case "@ADD_EDP_SUCCESS":
      return {
        ...state,
        addedp: payload,
        edpCreated: true,
        isLoading: false,
      };
    case "@ADD_EDP_FAILED":
    case "GET_EDP_PURCHASE_LIST_FAILED":
      return {
        ...state,
        errors: payload,
        edpCreated: false,
        isLoading: false,
      };

    case "GET_EDP_LIST_FULFILLED":
      return {...state, isLoading: false,edps:action.payload?.enterpreneurDevelopments,isLoading: false}

    case"GET_EDP_PURCHASE_LIST_FULFILLED":
      return {...state,isLoading:false,purchasedEdp:action.payload?.edpOrder}
    case "RESET_EDP_STATUS":
      return {
        ...state,
        edpCreated: false,
      };

    default:
      return state;
  }
};

export default edpReducer;
