import { put, call, all, takeEvery } from "redux-saga/effects";
import axios from "axios";

function* sendSms(action) {
  try {
    const response = yield call(
      axios.post,
      "/api/franchise/send-whats-app",
      action.payload,
      {
        headers: { Authorization: `Bearer ${action.token}` },
      }
    );
    yield put({ type: "@SEND_SMS_SUCCESS", payload: response.data });
  } catch (error) {
    yield put({
      type: "@SEND_SMS_FAILED",
      payload: error.response.data,
    });
  }
}

function* addSms(action) {
  try {
    const response = yield call(
      axios.post,
      "/api/franchise/add-whatsapp",
      action.payload,
      {
        headers: { Authorization: `Bearer ${action.token}` },
      }
    );
    yield put({ type: "@ADD_SMS_SUCCESS", payload: response.data });
  } catch (error) {
    yield put({
      type: "@ADD_SMS_FAILED",
      payload: error.response.data,
    });
  }
}

// export const addSms = (da, token) => {
//   return {
//     type: "ADD_SMS",
//     payload: Axios.post("/api/crm/add-whatsapp", da, {
//       headers: { Authorization: `Bearer ${token}` },
//     }),
//   };
// };

export default function* WhatsappSmsSaga() {
  yield all([
    takeEvery("@SEND_SMS_REQUEST", sendSms),
    takeEvery("@ADD_SMS_REQUEST", addSms),
  ]);
}
