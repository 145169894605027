import { put, takeLatest, call, all } from "redux-saga/effects";
import axios from "axios";

function* getProfile(action) {
  try {
    const response = yield call(
      axios.get,
      `/api/franchise/get-franchise/${action.payload.id}`,
      {
        headers: { Authorization: `Bearer ${action.payload.token}` },
      }
    );
    yield put({ type: "@GET_PROFILE_SUCCESS", payload: response.data });
  } catch (error) {
    yield put({ type: "@GET_PROFILE_FAILED", payload: error.response.data });
  }
}

function* updateProfile(action) {
  console.log(action.payload.auth.token);
  try {
    const response = yield call(
      axios.post,
      "/api/franchise/update-franchise",
      action.payload.data,
      {
        headers: { Authorization: `Bearer ${action.payload.auth.token}` },
      }
    );
    yield put({ type: "@UPDATE_PROFILE_SUCCESS", payload: response.data });
  } catch (error) {
    yield put({ type: "@UPDATE_PROFILE_FAILED", payload: error.response.data });
  }
}

export default function* ProfileSaga() {
  yield all([takeLatest("@GET_PROFILE_REQUEST", getProfile),
  takeLatest("@UPDATE_PROFILE_REQUEST", updateProfile)]);
}
