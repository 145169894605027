import _ from "lodash";

const initialState = {
  isLoading: false,
  errors: {},
  addedp: {},
  industrialreportbooked: {},
  bookedstatus: false,
};

const industrialreportReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "@INDUSTRIALREPORT_CREATE_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: {},
      };
    case "@INUDSTRIALREPORT_CREATE_SUCCESS":
      return {
        ...state,
        industrialreportbooked: payload,
        bookedstatus: true,
        isLoading: false,
      };
    case "@INDUSTRIALREPORT_CREATE_FAILED":
      return {
        ...state,
        errors: payload,
        bookedstatus: false,
        isLoading: false,
      };

    case "RESET_PROJECT_STATUS":
      return {
        ...state,
        bookedstatus: false,
      };

    default:
      return state;
  }
};

export default industrialreportReducer;
