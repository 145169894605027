import { put, takeLatest, call, all } from "redux-saga/effects";
import axios from "axios";

function* getMeeting(action) {
  try {
    const response = yield call(
      axios.get,
      `/api/franchise/get-meetingdetails?sort=${action?.sort}&page=${action?.page}&search=${action?.search}&crmNote=${action?.crmNote}&paginate=${action?.paginate}`,
      {
        headers: { Authorization: `Bearer ${action.payload}` },
      }
    );
    yield put({ type: "@GET_MEETING_SUCCESS", payload: response.data });
  } catch (error) {
    yield put({ type: "@GET_MEETING_FAILED", payload: error.response.data });
  }
}

function* getUserDetailsById(action) {
  try {
    const response = yield call(
      axios.get,
      `/api/franchise/getmeetingby/${action?.id}`,
      {
        headers: { Authorization: `Bearer ${action.payload}` },
      }
    );
    yield put({ type: "@GET_USER_DETAILS_BY_ID_FULFILLED", payload: response.data });
  } catch (error) {
    yield put({ type: "@GET_USER_DETAILS_BY_ID_FAILED", payload: error.response.data });
  }
}

function* getUserDetails(action) {
  try {
    const response = yield call(
      axios.get,
      `/api/franchise/getUserbyId/${action.payload.id}`,
      {
        headers: { Authorization: `Bearer ${action.payload.token}` },
      }
    );
    yield put({ type: "@GET_USER_DETAIL_FULFILLED", payload: response.data });
  } catch (error) {
    yield put({
      type: "@GET_USER_DETAIL_FAILED",
      payload: error.response.data,
    });
  }
}

function* addMeeting(action) {
  try {
    const response = yield call(
      axios.post,
      "/api/franchise/get-meetingdetails-create",
      action.payload,
      {
        headers: { Authorization: `Bearer ${action.token}` },
      }
    );
    yield put({ type: "@ADD_MEETING_SUCCESS", payload: response.data });
  } catch (error) {
    yield put({ type: "@ADD_MEETING_FAILED", payload: error.response.data });
  }
}

function* getMeetingById(action) {
  try {
    const response = yield call(
      axios.get,
      `/api/franchise/getmeetingby/${action.payload}`,

      {
        headers: { Authorization: `Bearer ${action.token}` },
      }
    );
    yield put({ type: "GET_MEETING_ID_SUCCESS", payload: response.data });
  } catch (error) {
    yield put({ type: "GET_MEETING_ID_FAILED", payload: error.response.data });
  }
}

function* updateMeeting(action) {
  try {
    const response = yield call(
      axios.post,
      "/api/franchise/get-meetingdetails-update",
      action.payload,
      {
        headers: { Authorization: `Bearer ${action.token}` },
      }
    );
    yield put({ type: "@UPDATE_MEETING_SUCCESS", payload: response.data });
  } catch (error) {
    yield put({ type: "@UPDATE_MEETING_FAILED", payload: error.response.data });
  }
}

function* deleteMeeting(action) {
  try {
    const response = yield call(
      axios.post,
      "/api/franchise/meeting-delete",
      action.payload,
      {
        headers: { Authorization: `Bearer ${action.token}` },
      }
    );
    yield put({ type: "@DELETE_MEETING_SUCCESS", payload: response.data });
  } catch (error) {
    yield put({ type: "@DELETE_MEETING_FAILED", payload: error.response.data });
  }
}

export default function* MeetingSaga() {
  yield all([
    takeLatest("@GET_MEETING_REQUEST", getMeeting),
    takeLatest("@GET_USER_DETAIL", getUserDetails),
    takeLatest("@GET_USER_DETAIL_BY_ID",getUserDetailsById)
  ]);
  yield all([takeLatest("@ADD_MEETING_REQUEST", addMeeting)]);
  yield all([takeLatest("GET_MEETING_ID_REQUEST", getMeetingById)]);
  yield all([takeLatest("@UPDATE_MEETING_REQUEST", updateMeeting)]);
  yield all([takeLatest("@DELETE_MEETING_REQUEST", deleteMeeting)]);
}
