/* eslint-disable default-case */
import _ from "lodash";
import { encrypted, decrypted } from "../../utils/helper";

const callToken = localStorage.getItem("iid-tele-call");

const initialState = {
  telecallauth: !!callToken ? JSON.parse(decrypted(callToken)) : {},
  isCallAuthenticated: !!callToken,
  loggedin: false,
  telecall: {},
  hangup: {},
  sendsms: {},
  send: false,
  error: {},
  loadingg: false,
  loading: false,
};

const callReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case "LOGIN_TO_CALL":
      state = {
        ...state,
        loadingg: true,
        error: {},
      };
      break;
    case "LOGIN_TO_CALL_FULFILLED":
      state = {
        ...state,
        loading: false,
        telecallauth: _.get(payload, "data", {}),
        isCallAuthenticated: true,
        loggedin: true,
      };

      localStorage.setItem("iid-tele-call", encrypted(JSON.stringify
        (state.telecallauth)));
      break;

    case "LOGIN_TO_CALL_REJECTED":
      var {
        payload: {
          response: { status, data },
        },
      } = action;
      if (status === 422) {
        state = {
          ...state,
          loading: false,
          error: _.get(data, "errors", {}),
        };
      } else {
        state = {
          ...state,
          loading: false,
          error: _.get(data, "message", {}),
        };
      }
      break;

    case "CALLING_PENDING":
      state = {
        ...state,
        loadingg: true,
        error: false,
      };
      break;
    case "CALLING_FULFILLED":
      state = {
        ...state,
        loadingg: false,
        telecall: _.get(payload, "data", {}),
      };

      break;

    case "CALLING_REJECTED":
      var {
        payload: {
          response: { status, data },
        },
      } = action;
      if (status === 422) {
        state = {
          ...state,
          loadingg: false,
          error: _.get(data, "errors", {}),
        };
      } else {
        state = {
          ...state,
          loadingg: false,
          error: _.get(data, "message", {}),
        };
      }
      break;

    case "SEND_SMS_PENDING":
      state = {
        ...state,
        loading: true,
        error: false,
      };
      break;
    case "SEND_SMS_FULFILLED":
      state = {
        ...state,
        loading: false,
        sendsms: _.get(payload, "data", {}),
        send: true,
      };

      break;

    case "RESET_SMS_STATUS":
      state = {
        ...state,
        send: payload,
      };
      break;

    case "SEND_SMS_REJECTED":
      var {
        payload: {
          response: { status, data },
        },
      } = action;
      if (status === 422) {
        state = {
          ...state,
          loading: false,
          error: _.get(data, "errors", {}),
        };
      } else {
        state = {
          ...state,
          loading: false,
          error: _.get(data, "message", {}),
        };
      }
      break;
    case "CALL_HANG_UP_PENDING":
      state = {
        ...state,
        loadingg: true,
        error: false,
      };
      break;
    case "CALL_HANG_UP_FULFILLED":
      state = {
        ...state,
        loadingg: false,
        hangup: _.get(payload, "data", {}),
      };

      break;

    case "CALL_HANG_UP_REJECTED":
      var {
        payload: {
          response: { status, data },
        },
      } = action;
      if (status === 422) {
        state = {
          ...state,
          loading: false,
          error: _.get(data, "errors", {}),
        };
      } else {
        state = {
          ...state,
          loading: false,
          error: _.get(data, "message", {}),
        };
      }
      break;
    case "ADD_SMS_PENDING":
      state = {
        ...state,
        loading: true,
        error: false,
      };
      break;
    case "ADD_SMS_FULFILLED":
      state = {
        ...state,
        loading: false,
        addsms: _.get(payload, "data", {}),
        send: true,
      };

      break;
    case "ADD_SMS_REJECTED":
      var {
        payload: {
          response: { status, data },
        },
      } = action;
      if (status === 422) {
        state = {
          ...state,
          loading: false,
          error: _.get(data, "errors", {}),
        };
      } else {
        state = {
          ...state,
          loading: false,
          error: _.get(data, "message", {}),
        };
      }
      break;
  }

  return state;
};

export default callReducer;
