const initialState = {
  isLoading: false,
  errors: {},
  addedp: {},
  projectreportbooked: {},
  customizereport: {},
  bookedstatus: false,
  customizereportstatus: false,
  projectReports: {},
  bookedprojectreportlist: [],
};

const projectreportReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "@PROJECTREPORT_CREATE_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: {},
      };
    case "@PROJECTREPORT_CREATE_SUCCESS":
      return {
        ...state,
        projectreportbooked: payload,
        bookedstatus: true,
        isLoading: false,
      };
    case "@PROJECTREPORT_CREATE_FAILED":
      return {
        ...state,
        errors: payload,
        bookedstatus: false,
        isLoading: false,
      };
    case "@CUSTOMIZE_PROJECTREPORT_CREATE_REQUEST":
      return {
        ...state,
        isLoading: true,
        customizereportstatus: false,
        errors: {},
      };
    case "@CUSTOMIZE_PROJECTREPORT_CREATE_SUCCESS":
      return {
        ...state,
        customizereport: payload,
        customizereportstatus: true,
        isLoading: false,
      };
    case "@CUSTOMIZE_PROJECTREPORT_CREATE_FAILED":
      return {
        ...state,
        errors: payload,
        customizereportstatus: false,
        isLoading: false,
      };
    case "@BOOKED_PROJECTREPORT_LIST_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: {},
      };
    case "@BOOKED_PROJECTREPORT_LIST_SUCCESS":
      return {
        ...state,
        bookedprojectreportlist: payload?.order,
        isLoading: false,
      };
    case "@BOOKED_PROJECTREPORT_LIST_FAILED":
      return {
        ...state,
        errors: payload,
        isLoading: false,
      };

    case "GET_PROJECT_REPORTS_LIST_FULLFILLED":
      return { ...state, projectReports: action.payload, isLoading: false };

    case "@GET_CUSTOMIZEREPORT_LIST_FULLFILLED":
      return { ...state, customizereport: action.payload, isLoading: false };

    case "RESET_PROJECT_STATUS":
      return {
        ...state,
        bookedstatus: false,
        customizereportstatus: false,
      };

    default:
      return state;
  }
};

export default projectreportReducer;
