import { put, takeLatest, call, all } from "redux-saga/effects";
import axios from "axios";
import {
    MONTHLY_SALE_TARGET_REQUEST,
    MONTHLY_SALE_TARGET_SUCCESS,
    MONTHLY_SALE_TARGET_FAILED,
    PARTNER_ALLOWED_PRODUCT_REQUEST,
    PARTNER_ALLOWED_PRODUCT_SUCCESS,
    PARTNER_ALLOWED_PRODUCT_FAILED
} from "../../utils/constant";

function* getSaleTarget(action) {
    try {
        const response = yield call(
            axios.get,
            `/api/franchise/sale-target?fk_franchise_type=${action?.fk_franchise_type}`,
            {
                headers: { Authorization: `Bearer ${action?.payload}` },
            }
        );
        yield put({ type: MONTHLY_SALE_TARGET_SUCCESS, payload: response.data });
    } catch (error) {
        yield put({ type: MONTHLY_SALE_TARGET_FAILED, payload: error.response.data });
    }
}
function* getAllowedProduct(action) {
    try {
        const response = yield call(
            axios.get,
            `/api/franchise/franchise-service?fk_franchise_type=${action?.fk_franchise_type}`,
            {
                headers: { Authorization: `Bearer ${action?.payload}` },
            }
        );
        yield put({ type: PARTNER_ALLOWED_PRODUCT_SUCCESS, payload: response.data });
    } catch (error) {
        yield put({ type: PARTNER_ALLOWED_PRODUCT_FAILED, payload: error.response.data });
    }
}

function* getDashboardStats(action) {
    try {
        const response = yield call(axios, "/api/franchise/get-dashboard", {
            headers: { Authorization: `Bearer ${action?.token}` }
        })
        yield put({ type: "GET_DASHBOARD_STATS_FULFILLED", payload: response.data })
    } catch (error) {
        yield put({ type: "GET_DASHBOARD_STATS_REJECTED", payload: error.response?.data })

    }
}
export default function* dashboardSaga() {
    yield all([takeLatest(MONTHLY_SALE_TARGET_REQUEST, getSaleTarget),
    takeLatest("GET_DASHBOARD_STATS", getDashboardStats)
    ]);
    yield all([takeLatest(PARTNER_ALLOWED_PRODUCT_REQUEST, getAllowedProduct)]);
}