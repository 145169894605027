import _ from "lodash";
import {
  UPCOMING_WORKSHOP_REQUEST,
  UPCOMING_WORKSHOP_SUCCESS,
  UPCOMING_WORKSHOP_FAILED,
  PAST_WORKSHOP_REQUEST,
  PAST_WORKSHOP_SUCCESS,
  PAST_WORKSHOP_FAILED,
} from "../../utils/constant";
const initialState = {
  isLoading: false,
  upcommingWp: [],
  pastWorkshop: [],
  errors: {},
  getworkshop: {},
  workshop: {},
  createworkshopstatus: false,
  bookedworkshoplist: [],
  workshopPayment: {},
};

const workshopReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPCOMING_WORKSHOP_REQUEST:
    case PAST_WORKSHOP_REQUEST:
    case "@GET_ALLWORKSHOP_REQUEST":
    case "GET_WORKSHOP_PAYMENT_LIST":
      return {
        ...state,
        isLoading: true,
        errors: {},
      };
    case "@GET_ALLWORKSHOP_SUCCESS":
      return {
        ...state,
        getworkshop: payload.upcomming,
        isLoading: false,
      };

    case UPCOMING_WORKSHOP_SUCCESS:
      return {
        ...state,
        upcommingWp: payload?.workshop,
        isLoading: false,
      };
    case PAST_WORKSHOP_SUCCESS:
      return {
        ...state,
        pastWorkshop: payload?.workshop,
        isLoading: false,
      };
    case UPCOMING_WORKSHOP_FAILED:
    case PAST_WORKSHOP_FAILED:
    case "@GET_ALLWORKSHOP_FAILED":
    case "GET_WORKSHOP_PAYMENT_LIST_FAILED":
      return {
        ...state,
        errors: payload.errors,
        isLoading: false,
      };

    case "@CREATE_WORKSHOP_REQUEST":
      return {
        ...state,
        isLoading: true,
        createworkshopstatus: false,
        errors: {},
      };
    case "@CREATE_WORKSHOP_SUCCESS":
      return {
        ...state,
        workshop: payload,
        createworkshopstatus: true,
        isLoading: false,
      };
    case "@CREATE_WORKSHOP_FAILED":
      return {
        ...state,
        errors: payload,
        isLoading: false,
        updateprofilestatus: false,
      };

    case "GET_WORKSHOP_PAYMENT_LIST_FULFILLED":
      return {
        ...state,
        workshopPayment: payload?.wpOrderList,
        isLoading: false,
      };

    case "@LIST_WORKSHOP_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: {},
      };
    case "@LIST_WORKSHOP_SUCCESS":
      return {
        ...state,
        bookedworkshoplist: payload?.bookWorkShop,
        isLoading: false,
      };
    case "@LIST_WORKSHOP_FAILED":
      return {
        ...state,
        errors: payload,
        isLoading: false,
      };

    case "RESET_WORKSHOP_STATUS":
      return {
        ...state,
        createworkshopstatus: false,
      };

    default:
      return state;
  }
};

export default workshopReducer;
