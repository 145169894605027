

const initialState = {
    industrialSolution: {},
    isLoading:false,
    errors:{}
}



const industrialSolutinReducer = (state = initialState, action) => {

    switch (action.type) {
        case "GET_INDUSTRY_SOLUTION_LIST":
            return { ...state, isLoading: true }
        case "GET_INDUSTRY_SOLUTION_LIST_FULLFILLED":
            return { ...state, industrialSolution: action.payload?.industrialSolutions, isLoading: false }
        case "GET_INDUSTRY_SOLUTIION_LIST_REJECTED":
            return {...state, isLoading: false,errors: action.payload}
        default:
            return state
    }
}

export default industrialSolutinReducer