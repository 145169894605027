import _ from "lodash";

const initialState = {
  memberShips: [],
  memberShipbyId: {},
  bookedMemberShips: [],
  bookedMemberShip: {},
  users: [],
  sectors: [],
  projects: [],
  loading: false,
  success: false,
  createStatus: false,
  errors: {},
  bookedMember: {},
  membershipcreatedStatus: false,
  allMemberships: {},
  purchasedMemberships: {},
};

const membershipReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "@MEMBERSHIP_REQUEST":
    case "GET_PURCHASED_MEMBERSHIPLIST":
      return {
        ...state,
        isLoading: true,
        errors: {},
      };
    case "@MEMBERSHIP_SUCCESS":
      return {
        ...state,
        memberShips: payload.memberShip,
        success: true,
        isLoading: false,
      };
    case "@MEMBERSHIP_FAILED":
      return {
        ...state,
        errors: payload,
        success: false,
        isLoading: false,
      };
    case "@SECTOR_LIST_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: {},
      };
    case "@SECTOR_LIST_SUCCESS":
      return {
        ...state,
        sectors: payload.subcategory,
        success: false,
        isLoading: false,
      };

    case "GET_MEMBERSHIPLIST_FULFILLED":
      return {
        ...state,
        isLoading: false,
        allMemberships: action.payload?.membership,
      };
    case "@SECTOR_LIST_FAILED":
    case "GET_MEMBERSHIPLIST_FAILED":
      return {
        ...state,
        errors: payload,
        edpCreated: false,
        isLoading: false,
      };
    case "@PROJECT_LIST_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: {},
      };
    case "@PROJECT_LIST_SUCCESS":
      return {
        ...state,
        projects: payload.project_reports,
        success: true,
        isLoading: false,
      };
    case "@PROJECT_LIST_FAILED":
      return {
        ...state,
        errors: payload,
        edpCreated: false,
        isLoading: false,
      };
    case "@MEMBERSHIP_CREATE_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: {},
      };
    case "@MEMBERSHIP_CREATE_SUCCESS":
      return {
        ...state,
        bookedMember: payload,
        membershipcreatedStatus: true,
        success: true,
        isLoading: false,
      };
    case "@MEMBERSHIP_CREATE_FAILED":
      return {
        ...state,
        errors: payload,
        membershipcreatedStatus: false,
        isLoading: false,
      };
    case "@MEMBERSHIP_BY_ID_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: {},
      };
    case "@MEMBERSHIP_BY_ID_SUCCESS":
      return {
        ...state,
        addedp: payload,
        edpCreated: true,
        isLoading: false,
      };
    case "@MEMBERSHIP_BY_ID_FAILED":
      return {
        ...state,
        errors: payload,
        edpCreated: false,
        isLoading: false,
      };

    case "@MEMBERSHIP_EDIT_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: {},
      };
    case "@MEMBERSHIP_EDIT_SUCCESS":
      return {
        ...state,
        addedp: payload,
        edpCreated: true,
        isLoading: false,
      };
    case "@MEMBERSHIP_EDIT_FAILED":
    case "GET_PURCHASED_MEMBERSHIPLIST_FAILED":
      return {
        ...state,
        errors: payload,
        edpCreated: false,
        isLoading: false,
      };

    case "GET_PURCHASED_MEMBERSHIPLIST_FULFILLED":
      return {
        ...state,
        purchasedMemberships: payload?.paidMembers,
        isLoading: false,
        errors: {},
      };

    case "RESET_MEMBERHSIP_STATUS":
      return {
        ...state,
        membershipcreatedStatus: false,
      };

    default:
      return state;
  }
};

export default membershipReducer;
