import { put, takeLatest, call, all } from "redux-saga/effects";
import axios from "axios";
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
} from "../../utils/constant";

function* login(action) {
  try {
    const response = yield call(
      axios.post,
      "/api/franchise/franchise-login",
      action.payload
    );
    yield put({ type: LOGIN_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: LOGIN_FAILED, payload: error.response.data });
  }
}

function* logout() {
  yield put({ type: LOGOUT_SUCCESS });
}

export default function* rootAuth() {
  yield all([
    takeLatest(LOGIN_REQUEST, login),
    takeLatest(LOGOUT_REQUEST, logout),
  ]);
}
