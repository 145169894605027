const initialState = {
  isLoading: false,
  errors: {},
  sendsms: {},
  sendsmsstatus: false,
  addsmsstatus: false,
  addsms: {},
};

const whatsappSmsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "@SEND_SMS_REQUEST":
      return {
        ...state,
        isLoading: true,
        sendsmsstatus: false,
        errors: {},
      };
    case "@SEND_SMS_SUCCESS":
      return {
        ...state,
        sendsms: payload,
        sendsmsstatus: true,
        isLoading: false,
      };
    case "@SEND_SMS_FAILED":
      return {
        ...state,
        errors: payload,
        sendsmsstatus: false,
        isLoading: false,
      };
    case "@ADD_SMS_REQUEST":
      return {
        ...state,
        isLoading: true,
        addsmsstatus: false,
        errors: {},
      };
    case "@ADD_SMS_SUCCESS":
      return {
        ...state,
        addsms: payload,
        addsmsstatus: true,
        isLoading: false,
      };
    case "@ADD_SMS_FAILED":
      return {
        ...state,
        errors: payload,
        addsmsstatus: false,
        isLoading: false,
      };

    case "RESET_SEND_SMS_STATUS":
      return {
        ...state,
        sendsmsstatus: false,
      };

    default:
      return state;
  }
};

export default whatsappSmsReducer;
