import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import LoadableApp from "./LoadableApp";
import reportWebVitals from "./reportWebVitals";
import store from "./store/store";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/app.css";
import "./assets/css/custom.css";
import "react-notifications/lib/notifications.css";
import "./index.css"
import "bootstrap/dist/js/bootstrap";
const rootId = document.getElementById("root");

ReactDOM.render(
  <Provider store={store}>
    <LoadableApp />
  </Provider>,
  rootId
);
reportWebVitals();
