import { put, takeLatest, call, all } from "redux-saga/effects";
import axios from "axios";

function* bookIndustrialReport(action) {
  try {
    const response = yield call(
      axios.post,
      "/api/franchise/book-projectreport",
      action.payload,
      {
        headers: { Authorization: `Bearer ${action.token}` },
      }
    );
    yield put({
      type: "@INDUSTRIALREPORT_CREATE_SUCCESS",
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: "@INDUSTRIALREPORT_CREATE_FAILED",
      payload: error.response.data,
    });
  }
}

export default function* IndustrialReportSaga() {
  yield all([
    takeLatest("@INDUSTRIALREPORT_CREATE_REQUEST", bookIndustrialReport),
  ]);
}
