const initialState = {
    isLoading: false,
    orderPayment: {},
    errors:{}
}
const OrderPaymentReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_ORDER_PAYMENT_LIST":
            return { ...state, isLoading: true }
        case "GET_ORDER_PAYMENT_LIST_FULFILLED":
            return { ...state, orderPayment: action.payload?.order, isLoading: false, }
        case"GET_ORDER_PAYMENT_LIST_FAILED":
        return {...state,isLoading:false,errors: action.payload?.errors}
        default:
            return { ...state }
    }
}

export default OrderPaymentReducer