import { all } from "redux-saga/effects";
import rootAuth from "./authSaga";
import ProfileSaga from "./profileSaga";
import MeetingSaga from "./MeetingSaga";
import WorkshopSaga from "./WorkshopSaga";
import EdpSaga from "./EdpSaga";
import ServiceSaga from "./ServiceSaga";
import MembershipSaga from "./MemberShipSaga";
import dashboardSaga from "./dashboardSaga";
import ProjectReportSaga from "./ProjectReportSaga";
import IndustrialReportSaga from "./IndustrialReportSaga";
import IndustrialSolutionSaga from "./IndustrialSolutionSaga";
import WhatsappSmsSaga from "./WhatsappSmsSaga";
import CourseSaga from "./CourseSaga";
import OrderPaymentSaga from "./OrderPaymentSaga";
import LoginCallSaga from "./LoginCallSaga";
import noteSaga from "./NoteSaga";
import userDetailSaga from "./userDetailSaga";
import taskSaga from "./TaskSaga"
export default function* indexSaga() {
  yield all([
    rootAuth(),
    ProfileSaga(),
    MeetingSaga(),
    WorkshopSaga(),
    EdpSaga(),
    ServiceSaga(),
    MembershipSaga(),
    dashboardSaga(),
    ProjectReportSaga(),
    IndustrialReportSaga(),
    IndustrialSolutionSaga(),
    WhatsappSmsSaga(),
    CourseSaga(),
    OrderPaymentSaga(),
    LoginCallSaga(),
    noteSaga(),
    userDetailSaga(),
    taskSaga()
  ]);
}
