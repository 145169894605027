import axios from "axios";
import { put, takeLatest, call, all } from "redux-saga/effects";
function* getIndustrySolutiionList(action) {
    try {
        const response = yield call(axios, `/api/franchise/industrial-solution-list?sort=${action?.sort}&page=${action?.page}&search=${action?.search}&paginate=${action?.paginate}`, {
            headers: { Authorization: `Bearer ${action?.payload}` }
        })
        console.log(response);
        yield put({ type: "GET_INDUSTRY_SOLUTION_LIST_FULLFILLED", payload: response.data });
    } catch (error) {
        yield put({
            type: "GET_INDUSTRY_SOLUTIION_LIST_REJECTED",
            payload: error?.response?.data,
        })
    }
}


export default function* IndustrialSolutionSaga() {
    yield all([takeLatest("GET_INDUSTRY_SOLUTION_LIST", getIndustrySolutiionList)]);
}