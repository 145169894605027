// "GET_COURSES_FULFILLED":

import _ from "lodash";

const initialState = {
  isLoading: false,
  errors: {},
  courses: [],
  bookcourse: {},
  coursepurchased: false,
};

const courseReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "GET_COURSE_PENDING":
      return {
        ...state,
        isLoading: true,
        errors: {},
      };
    case "GET_COURSES_FULFILLED":
      return {
        ...state,
        courses: payload.courses,
        isLoading: false,
      };
    case "GET_COURSE_REJECTED":
      return {
        ...state,
        errors: payload,
        edpCreated: false,
        isLoading: false,
      };
    case "@BOOK_COURSE_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: {},
      };
    case "@BOOK_COURSE_SUCCESS":
      return {
        ...state,
        bookcourse: payload,
        coursepurchased: true,
        isLoading: false,
      };
    case "@BOOK_COURSE_FAILED":
      return {
        ...state,
        errors: payload,
        coursepurchased: false,
        isLoading: false,
      };

    case "RESET_COURSE_STATUS":
      return {
        ...state,
        coursepurchased: false,
      };

    default:
      return state;
  }
};

export default courseReducer;
