import {
  MONTHLY_SALE_TARGET_REQUEST,
  MONTHLY_SALE_TARGET_SUCCESS,
  MONTHLY_SALE_TARGET_FAILED,
  PARTNER_ALLOWED_PRODUCT_REQUEST,
  PARTNER_ALLOWED_PRODUCT_SUCCESS,
  PARTNER_ALLOWED_PRODUCT_FAILED
} from "../../utils/constant";

const initialState = {
  saleTarget: {},
  allowedProduct: {},
  isLoading: false,
  errors: {},
  stats: {}
};

const dashboardReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case MONTHLY_SALE_TARGET_REQUEST:
    case "GET_DASHBOARD_STATS":
    case PARTNER_ALLOWED_PRODUCT_REQUEST:
      return {
        ...state,
        isLoading: true,
        errors: {},
      };
    case MONTHLY_SALE_TARGET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        saleTarget: payload?.saleTarget,
        errors: {},
      }
    case PARTNER_ALLOWED_PRODUCT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allowedProduct: payload?.franchiseServices,
        errors: {},
      }

    case "GET_DASHBOARD_STATS_FULFILLED":
      return {
        ...state,
        isLoading: false,
        stats: action.payload
      }
    case MONTHLY_SALE_TARGET_FAILED:
    case "GET_DASHBOARD_STATS_REJECTED":
    case PARTNER_ALLOWED_PRODUCT_FAILED:
      return {
        ...state,
        errors: payload,
        isLoading: false,
      };
    default:
      return state;
  }
}

export default dashboardReducer;