import { put, takeLatest, call, all } from "redux-saga/effects";
import axios from "axios";

function* bookEdp(action) {
  try {
    const response = yield call(
      axios.post,
      "/api/franchise/book-edp",
      action.payload,
      {
        headers: { Authorization: `Bearer ${action.token}` },
      }
    );
    yield put({ type: "@ADD_EDP_SUCCESS", payload: response.data });
  } catch (error) {
    yield put({
      type: "@ADD_EDP_FAILED",
      payload: error.response.data,
    });
  }
}


function* getEdpList(action) {
  try {
    const response = yield call(
      axios.get,
      `/api/franchise/edp-list?sort=${action?.sort}&page=${action?.page}&search=${action?.search}&paginate=${action?.paginate}`, {
      headers: { Authorization: `Bearer ${action?.payload}` }
    }
    );
    yield put({ type: "GET_EDP_LIST_FULFILLED", payload: response.data });
  } catch (error) {
    yield put({
      type: "GET_EDP_LIST_FAILED",
      payload: error.response.data,
    });
  }
}


function* getEdpPurchaseList(action) {
  try {
    const response = yield call(
      axios.get,
      `/api/franchise/edp-purchage-details?sort=${action?.sort}&page=${action?.page}&search=${action?.search}&paginate=${action?.paginate}&start=${action?.startDate}&end=${action?.endDate}&gateWayType=${action?.gatewayType}&memberType=${action?.status}`, {
      headers: { Authorization: `Bearer ${action?.payload}` }
    }
    );
    yield put({ type: "GET_EDP_PURCHASE_LIST_FULFILLED", payload: response.data });
  } catch (error) {
    yield put({
      type: "GET_EDP_PURCHASE_LIST_FAILED",
      payload: error.response.data,
    });
  }
}

export default function* EdpSaga() {
  yield all([takeLatest("@ADD_EDP_REQUEST", bookEdp),
  takeLatest("GET_EDP_PURCHASE_LIST", getEdpPurchaseList),
  takeLatest("GET_EDP_LIST",getEdpList)
]);
}
