import _ from "lodash"

const initialState = {
  userDetails: {},
  crmusersstatus: false,
  error: {},
  crmuser: {},
  loading: false,
}


const userDetailReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case "GET_USER_DETAIL":
      return {
        ...state,
        loading: true,
        error: {},
      };
    case "GET_USER_DETAIL_FULFILLED":
      return {
        ...state,
        loading: false,
        userDetails: payload?.detail,
      };
    case "GET_USER_DETAIL_FAILED":
      return {
        ...state,
        loading: false,
        users: _.get(payload, "data", {}),
      };


    default:
      return state;
  }
}

export default userDetailReducer

