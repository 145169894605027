import { all, put, takeLatest, call } from "redux-saga/effects"
import axios from "axios"
function* addTask(action) {
    try {
        const response = yield call(axios.post, "/api/franchise/add-task", action.data, {
            headers: {
                Authorization: `Bearer ${action.token}`,
            }
        })
        yield put({
            type: "ADD_TASK_FULFILLED",
            payload: response.data
        });

    } catch (error) {
        yield put({
            type: "ADD_TASK_FAILED",
            payload: error.response.data
        });

    }

}

function* getTasks(action) {
    try {
        const response = yield call(axios, `/api/franchise/fetch-lead-task/${action.id}?type=${action.task_type}`, {
            headers: {
                Authorization: `Bearer ${action.token}`,
            }
        })
        yield put({
            type: "GET_TASK_LIST_FULFILLED",
            payload: response.data
        });

    } catch (error) {
        yield put({
            type: "GET_TASK_LIST_FAILED",
            payload: error.response.data
        });

    }
}


function* getListActivity(action) {
    try {
        const response = yield call(axios, `/api/franchise/list-activities`, {
            headers: {
                Authorization: `Bearer ${action.token}`,
            }
        })
        yield put({
            type: "GET_LIST_ACTIVITY_FULFILLED",
            payload: response.data
        });

    } catch (error) {
        yield put({
            type: "GET_LIST_ACTIVITY_FAILED",
            payload: error.response.data
        });

    }
}


function* getAllReminders(action) {
    try {
        const response = yield call(axios, `/api/franchise/getallreminder`, {
            headers: {
                Authorization: `Bearer ${action.token}`,
            }
        })
        yield put({
            type: "GET_REMINDER_FULFILLED",
            payload: response.data
        });

    } catch (error) {
        yield put({
            type: "GET_REMINDER_FAILED",
            payload: error.response.data
        });

    }
}


function* getAllPriority(action) {
    try {
        const response = yield call(axios, `/api/franchise/all-priority`, {
            headers: {
                Authorization: `Bearer ${action.token}`,
            }
        })
        yield put({
            type: "GET_PRIORITY_FULFILLED",
            payload: response.data
        });

    } catch (error) {
        yield put({
            type: "GET_PRIORITY_FAILED",
            payload: error.response.data
        });

    }
}


function* updateTask(action) {
    try {
        const response = yield call(axios.post, `/api/franchise/update-task/${action.task_id}`,action.data, {
            headers: {
                Authorization: `Bearer ${action.token}`,
            }
        })
        yield put({
            type: "UPDATE_TASK_FULFILLED",
            payload: response.data
        });

    } catch (error) {
        yield put({
            type: "UPDATE_TASK_FAILED",
            payload: error.response.data
        });

    }
}

// all-priority
export default function* taskSaga() {
    yield all([
        takeLatest("ADD_TASK_PENDING", addTask),
        takeLatest("GET_TASK_LIST", getTasks),
        takeLatest("GET_LIST_ACTIVITY", getListActivity),
        takeLatest("GET_REMINDER", getAllReminders),
        takeLatest("GET_PRIORITY", getAllPriority),
        takeLatest("UPDATE_TASK",updateTask)
    ]);
}