export const LOGIN_REQUEST = "@LOGIN_REQUEST";
export const LOGIN_SUCCESS = "@LOGIN_SUCCESS";
export const LOGIN_FAILED = "@LOGIN_FAILED";

export const LOGOUT_REQUEST = "@LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "@LOGOUT_SUCCESS";


export const MONTHLY_SALE_TARGET_REQUEST = "@MONTHLY_SALE_TARGET_REQUEST";
export const MONTHLY_SALE_TARGET_SUCCESS = "@MONTHLY_SALE_TARGET_SUCCESS";
export const MONTHLY_SALE_TARGET_FAILED = "@MONTHLY_SALE_TARGET_FAILED";

export const PARTNER_ALLOWED_PRODUCT_REQUEST = "@PARTNER_ALLOWED_PRODUCT_REQUEST";
export const PARTNER_ALLOWED_PRODUCT_SUCCESS = "@PARTNER_ALLOWED_PRODUCT_SUCCESS";
export const PARTNER_ALLOWED_PRODUCT_FAILED = "@PARTNER_ALLOWED_PRODUCT_FAILED";

export const UPCOMING_WORKSHOP_REQUEST = "@UPCOMING_WORKSHOP_REQUEST";
export const UPCOMING_WORKSHOP_SUCCESS = "@UPCOMING_WORKSHOP_SUCCESS";
export const UPCOMING_WORKSHOP_FAILED = "@UPCOMING_WORKSHOP_FAILED";

export const PAST_WORKSHOP_REQUEST = "@PAST_WORKSHOP_REQUEST";
export const PAST_WORKSHOP_SUCCESS = "@PAST_WORKSHOP_SUCCESS";
export const PAST_WORKSHOP_FAILED = "@PAST_WORKSHOP_FAILED";