import _ from "lodash";

const initialState = {
  isLoading: false,
  errors: {},
  addservices: {},
  servicesCreated: false,
  services: {},
  purchasedServices: {},
  bookedServices: {},
  servicespurchased: false,
};

const servicesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "@ADD_SERVICES_REQUEST":
    case "GET_SERVICES_LIST":
    case "GET_PURCHASED_SERVICES_LIST":
    case "GET_BOOKED_SERVICES_LIST":
      return {
        ...state,
        isLoading: true,
        errors: {},
      };
    case "@ADD_SERVICES_SUCCESS":
      return {
        ...state,
        addServices: payload,
        servicesCreated: true,
        isLoading: false,
        servicespurchased: true,
      };

    case "RESET_PURCHASED":
      return {
        ...state,
        servicespurchased: action.payload,
      };
    case "@ADD_SERVICES_FAILED":
    case "GET_SERVICES_LIST_REJECTED":
    case "GET_PURCHASED_SERVICES_LIST_REJECTED":
    case "GET_BOOKED_SERVICES_LIST_REJECTED":
      return {
        ...state,
        errors: payload,
        servicesCreated: false,
        isLoading: false,
      };

    case "GET_SERVICES_LIST_FULLFILLED":
      return { ...state, isLoading: false, services: action.payload?.service };

    case "GET_PURCHASED_SERVICES_LIST_FULLFILLED":
      return {
        ...state,
        isLoading: false,
        purchasedServices: action.payload?.serviceOrder,
      };
    case "GET_BOOKED_SERVICES_LIST_FULLFILLED":
      return {
        ...state,
        isLoading: false,
        bookedServices: action.payload.bookedservices,
      };

    case "RESET_SERVICES_STATUS":
      return {
        ...state,
        servicesCreated: false,
      };

    default:
      return state;
  }
};

export default servicesReducer;
