import { put, takeLatest, call, all } from "redux-saga/effects";
import axios from "axios";

function* creatLeadNote(action) {
  try {
    const response = yield call(
      axios.post,
      "/api/franchise/add-employee-note",
      action.payload,
      {
        headers: { Authorization: `Bearer ${action.token}` },
      }
    );
    yield put({
      type: "@ADD_NOTE_SUCCESS",
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: "@ADD_NOTE_FAILED",
      payload: error.response.data,
    });
  }
}

function* getNoteList(action) {
  try {
    const response = yield call(
      axios.get,
      `/api/franchise/employee-note/${action.payload.id}`,
      {
        headers: { Authorization: `Bearer ${action.payload.token}` },
      }
    );
    yield put({
      type: "@GET_NOTE_SUCCESS",
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: "@GET_NOTE_FAILED",
      payload: error.response.data,
    });
  }
}

function* getUserNoteList(action) {
  try {
    const response = yield call(axios, `/api/franchise/user-employee-note/${action.payload.id}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    })
    yield put({ type: "GET_USER_NOTE_LIST_SUCCESS", payload: response.data })
  } catch (error) {
    yield put({ type: "GET_USER_NOTE_LIST_FAILED", payload: error.response.data })
  }
}

function* deleteNote(action) {
  try {
    const response = yield call(
      axios.post,
      `/api/franchise/employee-note-destroy`, action.payload,
      {
        headers: { Authorization: `Bearer ${action.token}` },
      }
    );
    yield put({
      type: "NOTE_DELETE_FULFILLED",
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: "NOTE_DELETE_REJECTED",
      payload: error.response.data,
    });
  }
}


function* updateNote(action) {
  try {
    const response = yield call(
      axios.post,
      `/api/franchise/employee-note-update`, action.payload,
      {
        headers: { Authorization: `Bearer ${action.token}` },
      }
    );
    yield put({
      type: "UPDATE_NOTE_FULFILLED",
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: "UPDATE_NOTE_REJECTED",
      payload: error.response.data,
    });
  }
}

export default function* noteSaga() {
  yield all([
    takeLatest("@ADD_NOTE_PENDING", creatLeadNote),
    takeLatest("@GET_NOTE_PENDING", getNoteList),
    takeLatest("NOTE_DELETE_PENDING", deleteNote),
    takeLatest("UPDATE_NOTE_PENDING", updateNote),
    takeLatest("GET_USER_NOTE_LIST",getUserNoteList)
  ]);
}