import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { logger } from "redux-logger/src";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./saga/indexSaga";
import authReducer from "./reducers/AuthReducer";
import profileReducer from "./reducers/ProfileReducer";
import meetingReducer from "./reducers/MeetingReducer";
import workshopReducer from "./reducers/WorkshopReducer";
import edpReducer from "./reducers/EdpReducer";
import servicesReducer from "./reducers/ServicesReducer";
import membershipReducer from "./reducers/MemberShipReducer";
import dashboardReducer from "./reducers/DashboardReducer";
import projectreportReducer from "./reducers/ProjectReportReducer";
import industrialreportReducer from "./reducers/IndustrialReportReducer";
import industrialSolutinReducer from "./reducers/IndustrialSolutinReducer";
import whatsappSmsReducer from "./reducers/WhatsappSmsReducer";
import courseReducer from "./reducers/CourseReducer";
import OrderPaymentReducer from "./reducers/OrderPaymentReducer";
import callReducer from "./reducers/CallReducer";
import callingReducer from "./reducers/callingReducer";
import noteReducer from "./reducers/NoteReducer";
import userDetailReducer from "./reducers/userDetailReducer";
import taskReducer from "./reducers/TaskReducer";
const sagaMiddleware = createSagaMiddleware();

const middleWares = [];

if (process.env.NODE_ENV !== "production") {
  middleWares.push(logger);
}
const store = createStore(
  combineReducers({
    authReducer,
    profileReducer,
    meetingReducer,
    workshopReducer,
    edpReducer,
    servicesReducer,
    membershipReducer,
    dashboard: dashboardReducer,
    projectreportReducer,
    industrialreportReducer,
    industrialSolutinReducer,
    whatsappSmsReducer,
    courseReducer,
    OrderPaymentReducer,
    callReducer,
    callingReducer,
    noteReducer,
    userDetailReducer,
    taskReducer
  }),
  compose(composeWithDevTools(applyMiddleware(sagaMiddleware, ...middleWares)))
);

sagaMiddleware.run(rootSaga);

export default store;
