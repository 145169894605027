import { put, takeLatest, call, all, takeEvery } from "redux-saga/effects";
import axios from "axios";

function* MemberShipData(action) {
  try {
    const response = yield call(
      axios.get,
      "/api/franchise/membership-master-api",
      {
        headers: { Authorization: `Bearer ${action.payload}` },
      }
    );
    yield put({ type: "@MEMBERSHIP_SUCCESS", payload: response.data });
  } catch (error) {
    yield put({
      type: "@MEMBERSHIP_FAILED",
      payload: error.response.data,
    });
  }
}

function* sectors(action) {
  try {
    const response = yield call(axios.get, "/api/franchise/sectordata", {
      headers: { Authorization: `Bearer ${action.payload}` },
    });
    yield put({ type: "@SECTOR_LIST_SUCCESS", payload: response.data });
  } catch (error) {
    yield put({
      type: "@SECTOR_LIST_FAILED",
      payload: error.response.data,
    });
  }
}

function* projects(action) {
  try {
    const response = yield call(
      axios.post,
      "/api/franchise/project-report",
      action.payload,
      {
        headers: { Authorization: `Bearer ${action.token}` },
      }
    );
    yield put({ type: "@PROJECT_LIST_SUCCESS", payload: response.data });
  } catch (error) {
    yield put({
      type: "@PROJECT_LIST_FAILED",
      payload: error.response.data,
    });
  }
}

function* memberShipCreate(action) {
  try {
    const response = yield call(
      axios.post,
      "/api/franchise/membership-registration",
      action.payload,
      {
        headers: { Authorization: `Bearer ${action.token}` },
      }
    );
    yield put({ type: "@MEMBERSHIP_CREATE_SUCCESS", payload: response.data });
  } catch (error) {
    yield put({
      type: "@MEMBERSHIP_CREATE_FAILED",
      payload: error.response.data,
    });
  }
}

function* memberShipList(action) {
  try {
    const response = yield call(
      axios.get,
      `/api/franchise/membership-master/details?sort=${action?.sort}&page=${action?.page}&search=${action?.search}&paginate=${action?.paginate}`,
      {
        headers: { Authorization: `Bearer ${action?.payload}` },
      }
    );
    yield put({ type: "GET_MEMBERSHIPLIST_FULFILLED", payload: response.data });
  } catch (error) {
    yield put({
      type: "GET_MEMBERSHIPLIST_FAILED",
      payload: error.response.data,
    });
  }
}

function* getMemberShipbyId(action) {
  try {
    const response = yield call(
      axios.post,
      `/api/franchise/membership/${action.membershipId}`,
      action.payload,
      {
        headers: { Authorization: `Bearer ${action.token}` },
      }
    );
    yield put({ type: "@ADD_EDP_SUCCESS", payload: response.data });
  } catch (error) {
    yield put({
      type: "@ADD_EDP_FAILED",
      payload: error.response.data,
    });
  }
}

function* memberShipUpdate(action) {
  try {
    const response = yield call(
      axios.post,
      `/api/franchise/membership/${action.membershipId}`,
      action.payload,
      {
        headers: { Authorization: `Bearer ${action.token}` },
      }
    );
    yield put({ type: "@ADD_ED_SUCCESS", payload: response.data });
  } catch (error) {
    yield put({
      type: "@ADD_EDP_FAILED",
      payload: error.response.data,
    });
  }
}

function* purchasedMemberShipList(action) {
  try {
    const response = yield call(
      axios.get,
      `/api/franchise/membership-registration-list?sort=${action?.sort}&page=${action?.page}&search=${action?.search}&paginate=${action?.paginate}&start=${action?.startDate}&end=${action?.endDate}&paymentStatus=${action?.status}&memberType=${action?.memberType}`,
      {
        headers: { Authorization: `Bearer ${action?.payload}` },
      }
    );
    yield put({
      type: "GET_PURCHASED_MEMBERSHIPLIST_FULFILLED",
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: "GET_PURCHASED_MEMBERSHIPLIST_FAILED",
      payload: error.response.data,
    });
  }
}

export default function* MembershipSaga() {
  yield all([
    takeLatest("@MEMBERSHIP_REQUEST", MemberShipData),
    takeLatest("GET_MEMBERSHIPLIST", memberShipList),
    takeEvery("GET_PURCHASED_MEMBERSHIPLIST", purchasedMemberShipList),
  ]);
  yield all([takeLatest("@SECTOR_LIST_REQUEST", sectors)]);
  yield all([takeLatest("@PROJECT_LIST_REQUEST", projects)]);
  yield all([takeLatest("@MEMBERSHIP_CREATE_REQUEST", memberShipCreate)]);
}
