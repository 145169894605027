import { NotificationManager } from "react-notifications"

const initialState = {
    taskData: [],
    loading: false,
    error: {},
    taskAdded: false,
    activities: [],
    reminders: [],
    priority: []
}


const taskReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_TASK_LIST':
        case "ADD_TASK_PENDING":
        case "GET_LIST_ACTIVITY":
            return {
                ...state, loading: true,
            }

        case 'GET_TASK_LIST_FULFILLED':
            return {
                ...state, loading: false,
                taskData: action.payload?.task
            }

        case "ADD_TASK_FULFILLED": {
            NotificationManager.success("Task added successfully")
            return {
                ...state,
                taskAdded: true
            }
        }

        case "GET_LIST_ACTIVITY_FULFILLED": {
            return {
                ...state,
                activities: action.payload?.activity
            }
        }

        case "GET_PRIORITY_FULFILLED": {
            return {
                ...state,
                priority: action.payload?.allPriority

            }
        }

        case "UPDATE_TASK_FULFILLED": {
            NotificationManager.success("Task updated successfully")
            return {
                ...state,
                taskAdded: true,
            }
        }

        case "GET_REMINDER_FULFILLED": {
            return {
                ...state,
                reminders: action.payload?.reminder
            }
        }
        case "SWITCH_STATE": {
            return {
                ...state,
                taskAdded: false

            }
        }


        case "GET_TASK_LIST_FAILED":
        case "GET_REMINDER_FAILED":
        case "GET_LIST_ACTIVITY_FAILED":
        case "GET_PRIORITY_FAILED":
        case "UPDATE_TASK_FAILED":
            NotificationManager.error("something went wrong")
            return {
                ...state, loading: false, error: action.payload
            }

        default:
            return state
    }
}


export default taskReducer