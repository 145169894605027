import { put, takeLatest, call, all } from "redux-saga/effects";
import axios from "axios";
import {
  UPCOMING_WORKSHOP_REQUEST,
  UPCOMING_WORKSHOP_SUCCESS,
  UPCOMING_WORKSHOP_FAILED,
  PAST_WORKSHOP_REQUEST,
  PAST_WORKSHOP_SUCCESS,
  PAST_WORKSHOP_FAILED,
} from "../../utils/constant";

function* getPayemtWorkshopList(action) {
  try {
    const response = yield call(
      axios.get,
      `/api/franchise/workshop-booking-list?sort=${action?.sort}&page=${action?.page}&search=${action?.search}&paginate=${action?.paginate}&start=${action?.startDate}&end=${action?.endDate}&gateWayType=${action?.gatewayType}&memberType=${action?.status}`,
      {
        headers: { Authorization: `Bearer ${action.payload}` },
      }
    );

    yield put({
      type: "GET_WORKSHOP_PAYMENT_LIST_FULFILLED",
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: "GET_WORKSHOP_PAYMENT_LIST_FAILED",

      payload: error.response.data,
    });
  }
}

function* getUpcomingWorkshop(action) {
  try {
    const response = yield call(axios.get, "/api/franchise/upcoming-workshop", {
      headers: { Authorization: `Bearer ${action.payload}` },
    });
    yield put({ type: UPCOMING_WORKSHOP_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({
      type: UPCOMING_WORKSHOP_FAILED,
      payload: error?.response?.data,
    });
  }
}
function* getPastWorkshop(action) {
  try {
    const response = yield call(
      axios.get,
      `/api/franchise/workshop-show?sort=${action?.sort}&page=${action?.page}&search=${action?.search}&paginate=${action?.paginate}`,
      {
        headers: { Authorization: `Bearer ${action.payload}` },
      }
    );
    yield put({ type: PAST_WORKSHOP_SUCCESS, payload: response.data });
  } catch (error) {
    console.log(error);
    yield put({
      type: PAST_WORKSHOP_FAILED,
      payload: error?.response?.data,
    });
  }
}

function* getallworkshop(action) {
  try {
    const response = yield call(axios.get, "/api/franchise/all-workshop", {
      headers: { Authorization: `Bearer ${action.payload}` },
    });
    yield put({ type: "@GET_ALLWORKSHOP_SUCCESS", payload: response.data });
  } catch (error) {
    yield put({
      type: "@GET_ALLWORKSHOP_FAILED",
      payload: error.response.data,
    });
  }
}

function* bookWorkshop(action) {
  try {
    const response = yield call(
      axios.post,
      "/api/franchise/book-workshop",
      action.payload,
      {
        headers: { Authorization: `Bearer ${action.token}` },
      }
    );
    yield put({ type: "@CREATE_WORKSHOP_SUCCESS", payload: response.data });
  } catch (error) {
    yield put({
      type: "@CREATE_WORKSHOP_FAILED",
      payload: error.response.data,
    });
  }
}

// function* getListWorkshop(action) {
//   try {
//     const response = yield call(
//       axios.get,
//       "/api/franchise/booked-user-workshop",
//       action.payload,
//       {
//         headers: { Authorization: `Bearer ${action.token}` },
//       }
//     );
//     yield put({ type: "@LIST_WORKSHOP_SUCCESS", payload: response.data });
//   } catch (error) {
//     yield put({
//       type: "@LIST_WORKSHOP_FAILED",
//       payload: error.response.data,
//     });
//   }
// }

function* BookedWorkshopList(action) {
  try {
    const response = yield call(
      axios.get,
      `/api/franchise/booked-user-workshop?user_id=${action.payload}`,
      {
        headers: { Authorization: `Bearer ${action.token}` },
      }
    );
    yield put({ type: "@LIST_WORKSHOP_SUCCESS", payload: response.data });
  } catch (error) {
    yield put({
      type: "@LIST_WORKSHOP_FAILED",
      payload: error.response.data,
    });
  }
}

export default function* WorkshopSaga() {
  yield all([
    takeLatest("@GET_ALLWORKSHOP_REQUEST", getallworkshop),
    takeLatest("GET_WORKSHOP_PAYMENT_LIST", getPayemtWorkshopList),
    takeLatest(UPCOMING_WORKSHOP_REQUEST,getUpcomingWorkshop),
    takeLatest(PAST_WORKSHOP_REQUEST,getPastWorkshop)
  ]);
  yield all([takeLatest("@CREATE_WORKSHOP_REQUEST", bookWorkshop)]);
  yield all([takeLatest("@LIST_WORKSHOP_REQUEST", BookedWorkshopList)]);
}
