import _ from "lodash";

const initialState = {
  isLoading: false,
  errors: {},
  getprofile: {},
  updateprofile: {},
  updateprofilestatus: false,
};

const profileReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "@GET_PROFILE_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: {},
      };
    case "@GET_PROFILE_SUCCESS":
      return {
        ...state,
        getprofile: payload.getprofile,
        isLoading: false,
      };
    case "@GET_PROFILE_FAILED":
      return {
        ...state,
        errors: payload,
        isLoading: false,
      };
    case "@UPDATE_PROFILE_REQUEST":
      return {
        ...state,
        isLoading: true,
        updateprofilestatus: false,
        errors: {},
      };
    case "@UPDATE_PROFILE_SUCCESS":
      return {
        ...state,
        updateprofile: payload,
        updateprofilestatus: true,
        isLoading: false,
      };
    case "@UPDATE_PROFILE_FAILED":
      return {
        ...state,
        errors: payload,
        isLoading: false,
        updateprofilestatus: false,
      };
    case "RESET_PROFILE_STATUS":
      return {
        ...state,
        updateprofilestatus: false,
      };

    default:
      return state;
  }
};

export default profileReducer;
