import { put, takeLatest, call, all } from "redux-saga/effects";
import axios from "axios";

function* bookProjectReport(action) {
  try {
    const response = yield call(
      axios.post,
      "/api/franchise/book-projectreport",
      action.payload,
      {
        headers: { Authorization: `Bearer ${action.token}` },
      }
    );
    yield put({
      type: "@PROJECTREPORT_CREATE_SUCCESS",
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: "@PROJECTREPORT_CREATE_FAILED",
      payload: error.response.data,
    });
  }
}

function* customizeProjectReport(action) {
  try {
    const response = yield call(
      axios.post,
      "/api/franchise/add-customize-report",
      action.payload,
      {
        headers: { Authorization: `Bearer ${action.token}` },
      }
    );
    yield put({
      type: "@PROJECTREPORT_CREATE_SUCCESS",
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: "@PROJECTREPORT_CREATE_FAILED",
      payload: error.response.data,
    });
  }
}

function* getProjectReportsList(action) {
  try {
    const response = yield call(
      axios,
      `/api/franchise/project-report-list?sort=${action?.sort}&page=${action?.page}&search=${action?.search}&paginate=${action?.paginate}`,
      {
        headers: { Authorization: `Bearer ${action?.payload}` },
      }
    );
    console.log(response);
    yield put({
      type: "GET_PROJECT_REPORTS_LIST_FULLFILLED",
      payload: response?.data?.projectReport,
    });
  } catch (error) {
    yield put({
      type: "GET_PROJECT_REPORTS_LIST_REJECTED",
      payload: error?.response?.data,
    });
  }
}

function* getCustomizeReportsList(action) {
  try {
    const response = yield call(
      axios,
      `/api/franchise/get-customize-report?sort=${action?.sort}&page=${action?.page}&search=${action?.search}&paginate=${action?.paginate}&paymentType=customized_project_report`,
      {
        headers: { Authorization: `Bearer ${action?.payload}` },
      }
    );
    console.log(response);
    yield put({
      type: "@GET_CUSTOMIZEREPORT_LIST_FULLFILLED",
      payload: response?.data?.customizeReports,
    });
  } catch (error) {
    yield put({
      type: "GET_CUSTOMIZEREPORT_LIST_REJECTED",
      payload: error?.response?.data,
    });
  }
}

function* bookedProjectReportList(action) {
  try {
    const response = yield call(
      axios.get,
      `/api/franchise/booked-project-report?user_id=${action.payload}`,
      {
        headers: { Authorization: `Bearer ${action.token}` },
      }
    );
    yield put({
      type: "@BOOKED_PROJECTREPORT_LIST_SUCCESS",
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: "@BOOKED_PROJECTREPORT_LIST_FAILED",
      payload: error.response.data,
    });
  }
}

export default function* ProjectReportSaga() {
  yield all([takeLatest("@PROJECTREPORT_CREATE_REQUEST", bookProjectReport)]);
  yield all([
    takeLatest(
      "@CUSTOMIZE_PROJECTREPORT_CREATE_REQUEST",
      customizeProjectReport
    ),
    takeLatest("GET_PROJECT_REPORTS_LIST", getProjectReportsList),
    takeLatest("@BOOKED_PROJECTREPORT_LIST_REQUEST", bookedProjectReportList),
    takeLatest("@GET_CUSTOMIZEREPORT_LIST_REQUEST", getCustomizeReportsList),
  ]);
}
