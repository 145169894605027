import _ from "lodash";

const initialState = {
  callData: [],
  callDataStatus: false,
  loading: false,

  addCall: [],
  addCallLoading: false,
  addStatus: false,

  getCallByIdStatus: false,
  getCallById: [],

  error: {},
};

const callingReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case "ADD_CALL_PENDING":
      return {
        ...state,
        addCallLoading: true,
        addStatus: false,
        error: {},
      };
    case "ADD_CALL_FULFILLED":
      return {
        ...state,
        addCallLoading: false,
        addStatus: true,
        addCall: _.get(payload, "data", {}),
      };
    case "ADD_CALL_REJECTED":
      const {
        payload: {
          response: { status, data },
        },
      } = action;
      if (status === 422) {
        return {
          ...state,
          addCallLoading: false,
          addStatus: false,
          error: _.get(data, "errors", {}),
        };
      } else {
        return {
          ...state,
          addCallLoading: false,
          addStatus: false,
          error: _.get(data, "message", {}),
        };
      }
    case "GET_CALL_BY_ID_PENDING":
      return {
        ...state,
        getCallByIdStatus: false,
        getCallById: [],
        error: {},
      };
    case "GET_CALL_BY_ID_FULFILLED":
      return {
        ...state,
        getCallByIdStatus: true,
        getCallById: _.get(payload, "data", {}),
      };
    case "GET_CALL_BY_ID_REJECTED":
      return {
        ...state,
        getCallByIdStatus: false,
        error: _.get(payload.response.data, "message", {}),
      };
    default:
      return state;
  }
};

export default callingReducer;
