import _ from "lodash";

const initialState = {
  isLoading: false,
  errors: {},
  getmeeting: {},
  addmeeting: {},
  addmeetingstatus: false,
  updatemeetingstatus: false,
  getmeetingidStatus: false,
  deletemeeting: {},
  deletemeetingstatus: false,
  userDetails: {},
  getmeetingbyid: {},
  updatemeeting: {},
  getUserDetail:{}
};

const meetingReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "@GET_MEETING_REQUEST":
    case "@GET_USER_DETAIL":
      return {
        ...state,
        isLoading: true,
        errors: {},
      };
    case "@GET_MEETING_SUCCESS":
      return {
        ...state,
        getmeeting: payload.meetingDetails,
        isLoading: false,
      };
    case "@GET_MEETING_FAILED":
    case "@GET_USER_DETAILS_BY_ID_FAILED":
    case "GET_USER_DETAIL_FAILED":
      return {
        ...state,
        errors: payload,
        isLoading: false,
      };

    case "@ADD_MEETING_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: {},
      };

    case "@GET_USER_DETAIL_FULFILLED":
      return {
        ...state,
        userDetails: action.payload.user,
      };


      case"@GET_USER_DETAILS_BY_ID_FULFILLED":
      return {
        ...state,
        isLoading:false,
        getUserDetail:action.payload?.meeting
      }
    case "@ADD_MEETING_SUCCESS":
      return {
        ...state,
        addmeeting: payload.meetingDetails,
        addmeetingstatus: true,
        isLoading: false,
      };
    case "@ADD_MEETING_FAILED":
      return {
        ...state,
        errors: payload,
        addmeetingstatus: false,
        isLoading: false,
      };
    case "GET_MEETING_ID_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: {},
      };

    case "GET_MEETING_ID_SUCCESS":
      return {
        ...state,
        getmeetingbyid: payload.meeting,
        isLoading: false,
      };

    case "GET_MEETING_ID_FAILED":
      return {
        ...state,
        errors: payload,
        isLoading: false,
      };

    case "@UPDATE_MEETING_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: {},
      };
    case "@UPDATE_MEETING_SUCCESS":
      return {
        ...state,
        updatemeeting: payload,
        updatemeetingstatus: true,
        isLoading: false,
      };
    case "@UPDATE_MEETING_FAILED":
      return {
        ...state,
        errors: payload,
        updatemeetingstatus: false,
        isLoading: false,
      };

    case "@DELETE_MEETING_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: {},
      };

    case "@DELETE_MEETING_SUCCESS":
      return {
        ...state,
        deletemeeting: payload,
        isLoading: false,
      };
    case "@DELETE_MEETING_FAILED":
      return {
        ...state,
        errors: payload,
        deletemeetingstatus: false,
        isLoading: false,
      };
    case "RESET_ADDMEETING_STATUS":
      return {
        ...state,
        addmeetingstatus: false,
        updatemeetingstatus: false,
      };

    default:
      return state;
  }
};

export default meetingReducer;
