import { put, takeLatest, call, all,takeEvery } from "redux-saga/effects";
import axios from "axios";
function* getPayemtOrderList(action) {
    try {
      const response = yield call(axios.get, `/api/franchise/order-payments?sort=${action?.sort}&page=${action?.page}&search=${action?.search}&paginate=${action?.paginate}&paymentType=${action.report_type}&start=${action?.startDate}&end=${action?.endDate}&gateWayType=${action?.gatewayType}&memberType=${action?.status}`, {
        headers: { Authorization: `Bearer ${action.payload}` },
      });
      yield put({ type: "GET_ORDER_PAYMENT_LIST_FULFILLED", payload: response.data });
    } catch (error) {
      yield put({
        type: "GET_ORDER_PAYMENT_LIST_FAILED",
        payload: error.response.data,
      });
    }
  }


  export default function* OrderPaymentSaga() {
    yield all([takeEvery("GET_ORDER_PAYMENT_LIST", getPayemtOrderList),]);

  }